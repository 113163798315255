<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import axios from "axios"
import GanttElastic from "gantt-elastic";
import moment from 'moment'

import {
  linewithDataChart,
  dashedLineChart,
  splineAreaChart,
  columnChart,
  columnDatalabelChart,
  barChart,
  mixedChart,
  radialChart,
  pieChart,
} from "./data-apex";

/**
 * Dashboard component
 */
export default {
  components: {
    Layout,
    PageHeader,
    GanttElastic,
  },
  data() {
    return {
      linewithDataChart: linewithDataChart,
      dashedLineChart: dashedLineChart,
      splineAreaChart: splineAreaChart,
      columnChart: columnChart,
      columnDatalabelChart: columnDatalabelChart,
      barChart: barChart,
      mixedChart: mixedChart,
      radialChart: radialChart,
      pieChart: pieChart,
      title: "Dashboard",
      dataChartAtas: [],
      baseapi: localStorage.getItem("baseapi"),
      idProyek: localStorage.getItem("idProyek"),
      user: JSON.parse(localStorage.getItem('user')),
      dataProyek: JSON.parse(localStorage.getItem('proyek')),
      items: [
        {
          text: "Project"
        },
        {
          text: "Dashboard",
          active: true
        }
      ],
      dir: false,
      dynamicStyle: {},
      lastId: 16,
      options: {
        taskMapping: {
          progress: "percent",
        },
        //maxRows: 100,
        maxHeight: 500,
        title: {
          label: "Dasboard",
          html: true,
        },
        row: {
          height: 24,
        },
        calendar: {
          hour: {
            display: false,
          },
        },
        chart: {
          progress: {
            bar: false,
          },
          expander: {
            display: true,
          },
        },
        taskList: {
          expander: {
            straight: false,
          },
          columns: [
            // {
            //   id: 1,
            //   label: "ID",
            //   value: "id",
            //   width: 40,
            // },
            {
              id: 2,
              label: "Task",
              value: "label",
              width: 200,
              expander: true,
              html: true,
            },
            {
              id: 4,
              label: "Weight",
              value: "bobot",
              width: 60,
              style: {
                "task-list-header-label": {
                  "text-align": "center",
                  width: "100%",
                },
                "task-list-item-value-container": {
                  "text-align": "center",
                  width: "100%",
                },
              },
            },
            {
              id: 5,
              label: "Progress",
              value: "progress",
              width: 100,
              style: {
                "task-list-header-label": {
                  "text-align": "center",
                  width: "100%",
                },
                "task-list-item-value-container": {
                  "text-align": "center",
                  width: "100%",
                },
              },
            },
          ],
        },
        locale: {
          name: "en",
          Now: "Now",
          "X-Scale": "Zoom-X",
          "Y-Scale": "Zoom-Y",
          "Task list width": "Task list",
          "Before/After": "Expand",
          "Display task list": "Task list",
        },
      },
      tasks: [],
      dataDashboard:{},

      statData: [
        {
          title: "Member",
          icon: "ri-team-fill",
          value: "10",
          subvalue: " 2.4% "
        },
        {
          title: "Task",
          icon: "ri-todo-fill",
          value: "$ 38452",
          subvalue: " 2.4% "
        },
        {
          title: "Selesai",
          icon: "ri-briefcase-4-line",
          value: "$ 15.4",
          subvalue: " 2.4% "
        }
      ]
    };
  },
  mounted(){
    this.loadDashboard()
    this.loadDataChartAtas()
  },
  methods: {
    format_date(value) {
            if (value) {
                return moment(String(value)).format('ddd, DD MMMM YYYY ')
            }
    },
    format: value => (value + '').replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, "."),
    parseDate(start, end) {
      let startDate = new Date(start);
      let endDate = new Date(end);
      let difference = endDate - startDate;
      let oned = 24 * 60 * 60 * 1000;
      return Math.ceil(difference / oned);
    },
    loadDashboard(){
      axios
        .get(
          this.baseapi +
          "api/serve_data/dashboard/" +
          this.dataProyek.id,
          {
            headers: {
              Authorization: `Bearer ` + this.user.accessToken
            }
          }
        ).then(response =>{
          this.dataDashboard = response.data.data
        })
    },
    loadDataChartAtas() {
      axios
        .get(
          this.baseapi +
          "api/task?filter=id_proyek,=," +
          this.idProyek+'&sort=kode_task,ASC',
          {
            headers: {
              Authorization: `Bearer ` + this.user.accessToken
            }
          }
        )
        .then((response) => {
          var data = response.data.data;
          console.log(data)
          for (let i = 0; i < data.length; i++) {
            // var dataDurasi = this.parseDate(data[i].start, data[i].end);
            console.log(data[i].id_predecessors);
            this.dataChartAtas.push({
              id: data[i].id,
              label: data[i].kode_task + ". " + data[i].nama_task,
              start: data[i].start,
              duration: data[i].durasi * 24 * 60 * 60 * 1000,
              type: data[i].type,
              style: {
                base: {
                  fill: data[i].color,
                  stroke: "#ffffff",
                },
              },
              parentId: data[i].parent,
              bobot: data[i].bobot,
              percent: data[i].progres,
              dependentOn: [data[i].id_predecessors],
            });
            
          }
          console.log(this.dataChartAtas);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    tasksUpdate(tasks) {
      this.tasks = tasks;
    },
    optionsUpdate(options) {
      this.options = options;
    },
    styleUpdate(style) {
      this.dynamicStyle = style;
    },
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-md-4">
        <div class="card">
          <div class="card-body">
            <div class="media">
              <div class="media-body overflow-hidden">

                <h4 class="mb-0">{{dataDashboard.task}}</h4>
                <p class="text-truncate font-size-25 mt-2 mb-0">Task</p>
              </div>
            </div>
          </div>
          <div class="card-body border-top py-3 mb-2">
            <div class="text-truncate">
              <b-progress class="mt-2" :max="100" show-value height="1rem" :value="dataDashboard.project.progres">
              </b-progress>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="card">
          <div class="card-body">
            <div class="media">
              <div class="media-body overflow-hidden">

                <h4 class="mb-0">Rp. {{format(dataDashboard.project.nilai_proyek)}}</h4>
                <p class="text-truncate font-size-25 mt-2 mb-0">Project Value</p>
              </div>
            </div>
          </div>
          <div class="card-body border-top py-3 mb-2">
            <div class="text-truncate">
              <span class="badge badge-soft-success font-size-11">
                <i class="mdi mdi-timelapse"></i>
                {{format_date(dataDashboard.project.waktu_selesai)}}
              </span>
              <span class="text-muted ml-2">Project Completion Date</span>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="card">
          <div class="card-body">
            <div class="media">
              <div class="media-body overflow-hidden">

                <h4 class="mb-0">{{ dataDashboard.member_count }}</h4>
                <p class="text-truncate font-size-25 mt-2 mb-0">Members</p>
              </div>
            </div>
          </div>
          <div class="card-body border-top py-3">
            <div class="text-truncate">
              <li class="list-inline-item" v-for="(data,index) in dataDashboard.member_list.slice(0,10)" :key="index">
                <a href="javascript: void(0);" class="d-inline-block" v-b-tooltip.hover :title="data.nama">
                  <div class="avatar-xs">
                    <span class="avatar-title rounded-circle bg-soft-primary text-primary">{{ data.nama.charAt(0) }}</span>
                  </div>
                </a>
              </li>
              

            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-12" v-if="dataChartAtas.length != 0">
        <div class="card">
          <div class="card-body">

            <gantt-elastic
                  :options="options"
                  :tasks="dataChartAtas"
                  @dynamic-style-changed="styleUpdate"
                  style="background-color: black"
                >
                  <!-- <gantt-header slot="header"></gantt-header> -->
                </gantt-elastic>
          </div>
        </div>
      </div>

      <!-- <div class="col-lg-6">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">Burndown Chart</h4>
            <apexchart class="apex-charts" height="380" type="line" dir="ltr" :series="dashedLineChart.series"
              :options="dashedLineChart.chartOptions"></apexchart>
          </div>
        </div>
      </div>

      <div class="col-lg-6">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">S-Curve</h4>
            <apexchart class="apex-charts" height="350" type="area" dir="ltr" :series="splineAreaChart.series"
              :options="splineAreaChart.chartOptions"></apexchart>
          </div>
        </div>
      </div> -->
    </div>
  </Layout>
</template>

<style scoped>
#ganttastic-wrapper {
  height: 50vh;
  overflow-y: auto;
}
</style>
